import { FORM_ACTIONS } from "./formActions.js";

export type DualInput<T> = {
  [index: string]: any;
  input: T;
  unit: string;
};

export interface Field<T> {
  [index: string]: any;
  value: T;
  visibility: boolean;
  optional: boolean;
}

export interface SpellCardInfo {
  [index: string]: any;
  id?: number;
  zindex?: number;
  spellname: Field<string>;
  spelllevel: Field<string>;
  spellschool: Field<string>;
  spelltype: Field<string>;
  casttime: Field<string>;
  range: Field<DualInput<string>>;
  duration: Field<string>;
  spelltarget: Field<string>;
  damage: Field<DualInput<number>>;
  spelldesc: Field<string>;
  upcastdesc: Field<string>;
  spellcomp: Field<[boolean, boolean, boolean]>;
  spellritual: Field<boolean>;
  spellcon: Field<boolean>;
  spellcolor: Field<string>;
  spellimg: Field<string | number>;
}

export type FormActionType = {
  type: string;
  field?: {
    name?: string;
    input?: string;
    value?: string | number | boolean;
    id?: string | number;
  };
  payload?: any;
};

export const INITIAL_STATE: SpellCardInfo = {
  spellname: { value: "", visibility: true, optional: false },

  spelllevel: { value: "", visibility: true, optional: false },
  spellschool: { value: "", visibility: true, optional: false },
  spelltype: { value: "", visibility: true, optional: false },

  casttime: { value: "", visibility: true, optional: false },
  range: {
    value: { input: "", unit: "" },
    visibility: true,
    optional: false,
  },
  duration: { value: "", visibility: true, optional: false },
  spelltarget: { value: "", visibility: true, optional: true },
  damage: {
    value: { input: NaN, unit: "" },
    visibility: true,
    optional: true,
  },

  spelldesc: { value: "", visibility: true, optional: true },
  upcastdesc: { value: "", visibility: true, optional: true },

  spellcomp: {
    value: [false, false, false],
    visibility: true,
    optional: false,
  },
  spellritual: { value: false, visibility: true, optional: false },
  spellcon: { value: false, visibility: true, optional: false },

  spellcolor: { value: "#A1E5F3", visibility: true, optional: false },
  spellimg: { value: "none", visibility: true, optional: false },
};

export const formReducer = (state: SpellCardInfo, action: FormActionType) => {
  const { type, field, payload } = action;

  //console.log("Dispatching Event");
  switch (type) {
    case FORM_ACTIONS.RESET:
      return {
        ...INITIAL_STATE,
      };
    case FORM_ACTIONS.SET_FIELDS:
      return {
        ...payload,
      };
    case FORM_ACTIONS.SET_INPUT:
      //console.log(field.name);
      //console.log(state.spellname.value);
      if (field !== undefined && field?.name !== undefined) {
        return {
          ...state,
          [field.name]: {
            ...state[field.name],
            value: field.value,
          },
        };
      }
      break;
    case FORM_ACTIONS.SET_INPUTSELECT:
      if (field !== undefined && field?.name !== undefined) {
        if (field.input === "select") {
          return {
            ...state,
            [field.name]: {
              ...state[field.name],
              value: {
                ...state[field.name]["value"],
                unit: field.value,
              },
            },
          };
        } else {
          return {
            ...state,
            [field.name]: {
              ...state[field.name],
              value: {
                ...state[field.name]["value"],
                input: field.value,
              },
            },
          };
        }
      }
      break;
    case FORM_ACTIONS.SET_CHECKBOX:
      console.log("checkbox handled");
      if (field !== undefined && field?.name !== undefined) {
        return {
          ...state,
          [field.name]: {
            ...state[field.name],
            value: !state[field.name]["value"],
          },
        };
      }
      break;
    case FORM_ACTIONS.SET_MULTISELECT:
      //console.log(field.id);
      if (field !== undefined && field?.id !== undefined) {
        return {
          ...state,
          spellcomp: {
            ...state.spellcomp,
            value: state.spellcomp.value.map((item, index) => {
              //console.log(item);
              if (index === field.id) {
                return !item;
              } else {
                return item;
              }
            }),
          },
        };
      }
      break;
    case FORM_ACTIONS.SET_VIS:
      if (field !== undefined && field?.name !== undefined) {
        console.log(state[field.name]["visibility"]);
        if (state[field.name]["optional"] === true) {
          return {
            ...state,
            [field.name]: {
              ...state[field.name],
              visibility: !state[field.name]["visibility"],
            },
          };
        } else {
        }
      }
      break;
    case FORM_ACTIONS.SET_IMAGE:
      //console.log(field.value);
      if (field !== undefined && field?.name !== undefined) {
        return {
          ...state,
          spellimg: {
            ...state.spellimg,
            value: field.value,
          },
        };
      }
      break;
    case FORM_ACTIONS.SET_COLOR:
      if (field !== undefined && field?.name !== undefined) {
        return {
          ...state,
          [field.name]: {
            ...state[field.name],
            value: field.value,
          },
        };
      }
      break;
    default:
      return state;
  }
};
