import { useRef, useState } from "react";
import { SpellbookEntry, IndexButton } from "./SpellbookEntry";
import { motion } from "framer-motion";
import { SpellCardInfo } from "./formReducer";

// ? import { SPELLBOOK_ACTIONS } from "./spellbookActions.js";

export function SpellBook(props: {
  userCards: SpellCardInfo[];
  spellbook: SpellCardInfo[];
  setSpellbook: Function;
  clearCards: Function;
  editCard: Function;
  copyCard: Function;
  deleteCard: Function;
  isEditing: boolean;
  setSort: Function;
  toggleIndex: Function;
  showIndex: boolean;
  raiseCard: Function;
  focusCard: Function;
}) {
  const {
    userCards,
    spellbook,
    setSpellbook,
    clearCards,
    editCard,
    copyCard,
    deleteCard,
    isEditing,
    setSort,
    toggleIndex,
    showIndex,
    raiseCard,
    focusCard,
  } = props;

  // State for Search
  const [query, setQuery] = useState<string>("");

  /**
   * Sorts the cards in the CardIndex by the optionVal
   * @param {string} optionVal - Name of the option selected
   */
  const sortCards = (optionVal: string) => {
    let cards = [...spellbook];

    setSort(optionVal);
    let sortSelected = optionVal;

    if (sortSelected === "level") {
      //console.log("Sorting by Level!");
      cards.sort((a, b) => {
        if (a.spelllevel.value < b.spelllevel.value) {
          return -1;
        }
        if (a.spelllevel.value > b.spelllevel.value) {
          return 1;
        }
        return 0;
      });
    } else if (sortSelected === "school") {
      //console.log("Sorting by School!");
      cards.sort((a, b) => {
        if (a.spellschool.value < b.spellschool.value) {
          return -1;
        }
        if (a.spellschool.value > b.spellschool.value) {
          return 1;
        }
        return 0;
      });
    } else if (sortSelected === "name") {
      //console.log("Sorting by Name!");
      cards.sort((a, b) => {
        if (a.spellname.value.toLowerCase() < b.spellname.value.toLowerCase()) {
          return -1;
        }
        if (a.spellname.value.toLowerCase() > b.spellname.value.toLowerCase()) {
          return 1;
        }
        return 0;
      });
    } else {
      //console.log("Sorting by ID!");
      cards.sort((a, b) => {
        if (a.id && b.id) {
          if (a.id > b.id) {
            return -1;
          }
          if (a.id < b.id) {
            return 1;
          }
          return 0;
        } else {
          console.error("Can't sort??");
          return 0;
        }
      });
    }
    setSpellbook(cards);
  };

  // * Search Functions ------------------
  /**
   * Converts property values to strings
   * @param {*} input - Property value
   * @param {string} objInput - Input property of value Object
   * @param {string} objUnit - Unit property of value Object
   * @returns property value as a string
   */
  function parseValues(input: any, objInput: string, objUnit: string) {
    if (input instanceof Array) {
      //console.log("Instance of Array Detected");

      return input
        .map((component, index) => {
          if (index === 0 && component === true) {
            return "verbal";
          } else if (index === 1 && component === true) {
            return "somatic";
          } else if (index === 2 && component === true) {
            return "material";
          } else {
            return "";
          }
        })
        .filter(Boolean)
        .toString()
        .replaceAll(" ", "");
    } else if (input instanceof Object) {
      return `${objInput}${objUnit}`;
    } else {
      return input.toString().toLowerCase();
    }
  }

  /**
   * Searches object props if query starts with "*:"
   * @param {Object} card - Card being searched
   * @param {string} query - Search query
   * @returns true/false
   */
  function searchByProp(card: SpellCardInfo, query: string) {
    //console.log(card);
    let propName = query
      .split(":")
      .shift()
      ?.trim()
      .replaceAll(" ", "")
      .toLowerCase();
    let propVal = query.split(":").pop()?.trim();
    let altPropName = "spell" + propName;

    console.log(propName + ":" + propVal);

    let cardVal;
    if (card[`${propName}`] !== undefined) {
      cardVal = parseValues(
        card[`${propName}`]["value"],
        card[`${propName}`]["value"]["input"],
        card[`${propName}`]["value"]["unit"],
      );
    } else if (card[`${altPropName}`] !== undefined) {
      cardVal = card[`${altPropName}`]["value"];
    }

    let cardVis;
    if (card[`${propName}`] !== undefined) {
      cardVis = card[`${propName}`]["visibility"];
    } else if (card[`${altPropName}`] !== undefined) {
      cardVis = card[`${altPropName}`]["visibility"];
    }
    console.log("propVal = " + propVal);
    console.log("cardVal = " + cardVal);

    console.log("Querying " + propName + "...");

    if (
      cardVal &&
      propVal?.localeCompare(cardVal, undefined, { sensitivity: "accent" }) ===
        0
    ) {
      console.log("Exact match was found");
      return true;
    } else if (
      cardVal &&
      cardVal.toLowerCase().indexOf(propVal?.toLowerCase()) > -1 &&
      propVal !== ""
    ) {
      console.log("Index was found");
      return true;
    } else if (propVal === "none" && cardVis === false) {
      // console.log("None was found");
      return true;
    } else {
      //console.warn("No " + propName + " values match " + propVal + " on this card");
    }
  }

  /**
   * Checks if an array's entries contain a string
   * @param {Array} array
   * @param {string} query
   * @returns true/false
   */
  function arrContainsQuery(array: [], query: string) {
    var containsQuery;
    array.forEach((string: string) => {
      // console.log(string);
      // console.log(string.indexOf(query));
      if (string.indexOf(query.toLowerCase()) > -1) {
        containsQuery = true;
      }
    });
    return containsQuery ? true : false;
  }

  function searchCards(query: string) {
    console.log("Filtering cards by query...");
    console.log("The query is:" + query);

    //Create shallow copy of userCards
    let cards = [...userCards];

    //Create shallow copy of cards to optimize for searching
    let cardValues: any[] = [...cards];
    cardValues = cardValues.map((card: SpellCardInfo) => {
      // console.log(Object.values(card));
      return Object.values(card).map((property) => {
        if (property.value) {
          return parseValues(
            property.value,
            property.value.input,
            property.value.unit,
          );
        } else {
          return "";
        }
      });
    });
    console.log(cardValues);

    //Filter cards by name
    cards = cards.filter((card, index) => {
      // If no query, don't filter
      if (query === "") {
        //console.log("Nothing is being searched");
        return card;
      } else if (query.indexOf(":") > -1 && searchByProp(card, query)) {
        return card;
      } else if (
        cardValues[index].includes(query) ||
        arrContainsQuery(cardValues[index], query) === true
      ) {
        console.log("An entry has matched your query");
        // console.log(card);
        return card;
      } else {
        return null; //console.log("This card does not match");
      }
    });
    setSpellbook(cards);
  }

  /**
   * Exports card for printing
   */
  function printCards() {
    window.print();
  }

  const swipeRef = useRef(0);

  const handleTouchStart = (event: React.TouchEvent<HTMLDivElement>) => {
    (event.target as HTMLElement).setAttribute("data-event", "swipe");
    swipeRef.current = event.targetTouches[0].clientY;
    console.log(swipeRef.current);
  };
  const handleTouchEnd = (event: React.TouchEvent<HTMLDivElement>) => {
    (event.target as HTMLElement).setAttribute("data-event", "none");

    console.log(event.changedTouches[0].clientY);
    console.log(showIndex);
    if (showIndex && event.changedTouches[0].clientY > swipeRef.current + 50) {
      console.log("swiped down");
      toggleIndex();
    } else if (
      showIndex === false &&
      event.changedTouches[0].clientY < swipeRef.current - 100
    ) {
      console.log("swiped up");

      toggleIndex();
    } else {
    }
  };

  return (
    <motion.div
      layout
      className={
        showIndex
          ? "peer/spellbook group z-30 mt-4 flex h-full min-w-full flex-col items-start justify-start gap-0 divide-y divide-black/20 self-stretch overflow-x-hidden overflow-y-scroll bg-white py-0 md:relative md:mt-0 md:h-screen md:min-w-64 md:shrink-0 md:self-stretch md:shadow-2xl"
          : "peer/spellbook group z-20 mt-auto flex h-fit min-w-full flex-col items-start justify-start gap-0 overflow-x-hidden overflow-y-hidden bg-white shadow-lg-top md:h-screen md:w-24 md:min-w-0 md:px-4 md:py-0 md:shadow-2xl"
      }
      data-open={showIndex ? "true" : "false"}
      data-print="false"
    >
      {/*<Sort=================/>*/}
      <motion.div
        id="spellbook-controls"
        layout="position"
        className="sticky top-0 z-10 flex w-full flex-col gap-4 border-b border-black/20 bg-white px-5 pb-8 pt-8 transition-colors has-[[data-event=swipe]]:bg-purple/10 data-[event=swipe]:bg-purple/10 group-data-[open='false']:border-b-0 md:justify-start md:pt-10"
        onTouchStart={(e) => handleTouchStart(e)}
        onTouchEnd={(e) => handleTouchEnd(e)}
      >
        <button
          id="card-library-collapse-btn"
          className="material-symbols-outlined absolute right-4 z-20 !block w-fit text-4xl md:sticky md:top-6 md:self-start md:group-data-[open='false']:left-auto md:group-data-[open='false']:self-center"
          tabIndex={0}
          onClick={(e) => toggleIndex}
        >
          menu
        </button>
        <h2 className="pointer-events-none text-nowrap font-sans text-3xl font-bold text-black md:text-4xl md:group-data-[open=false]:invisible md:group-data-[open=false]:w-0">
          Spellbook 📔
        </h2>
        <Search
          query={query}
          setQuery={setQuery}
          searchCards={searchCards}
        ></Search>
        <label
          className="pointer-events-none text-nowrap font-sans text-2xl font-medium text-black sm:text-xl md:group-data-[open=false]:invisible md:group-data-[open=false]:w-0"
          htmlFor="sort-select"
        >
          Sort by:
        </label>
        <select
          id="sort-select"
          className="border-1 flex justify-start rounded-md border border-black p-1 font-sans text-base font-normal focus-within:outline focus-within:outline-4 focus-within:outline-offset-1 focus-within:outline-purple md:group-data-[open=false]:invisible md:group-data-[open=false]:w-0"
          defaultValue="none"
          tabIndex={0}
          onChange={(e) => sortCards(e.target.value)}
        >
          <option id="sort-none" value="none">
            Last Created
          </option>

          <option id="sort-name" value="name">
            Name
          </option>

          <option id="sort-level" value="level">
            Level
          </option>

          <option id="sort-school" value="school">
            School
          </option>
        </select>
      </motion.div>
      <motion.div
        layout="position"
        id="card-items"
        className="w-full divide-y divide-black/20 group-data-[open=false]:invisible group-data-[open=false]:h-0 md:group-data-[open=false]:h-auto"
      >
        {/*Card Items------------*/}

        {spellbook?.map((card, index) => {
          //console.log(index);
          return (
            <SpellbookEntry
              key={card.id}
              order={index}
              id={card.id}
              name={card.spellname.value}
              level={card.spelllevel.value}
              school={card.spellschool.value}
              handleEdit={editCard}
              handleCopy={copyCard}
              handleDelete={deleteCard}
              isEditing={isEditing}
              raiseCard={raiseCard}
              focusCard={focusCard}
            />
          );
        })}
      </motion.div>
      <motion.div
        layout="position"
        id="index-controls"
        className={
          spellbook?.length > 0
            ? "sticky bottom-0 left-0 z-10 mt-auto flex w-full flex-row items-end justify-start gap-4 bg-white px-8 pb-8 pt-5 group-data-[open=false]:invisible group-data-[open=false]:h-0 group-data-[open=false]:py-0 md:bottom-0 md:flex-col md:group-data-[open=false]:h-auto md:group-data-[open=false]:pb-8 md:group-data-[open=false]:pt-5 md:group-data-[open=false]:*:w-0"
            : "hidden"
        }
      >
        <IndexButton
          btnName="Export Cards"
          id="export-cards"
          handleButton={printCards}
        />
        <IndexButton
          btnName="Clear All Cards"
          id="clear-cards"
          handleButton={clearCards}
        />
      </motion.div>
    </motion.div>
  );
}

export function Search(props: {
  query: string;
  setQuery: Function;
  searchCards: Function;
}) {
  const { query, setQuery, searchCards } = props;
  /**
   * Set search input
   * @param {string} searchQuery - Search submitted to the form
   */
  const handleSearchForm = (searchQuery: string) => {
    setQuery(searchQuery);
    searchCards(searchQuery);
  };

  return (
    <input
      tabIndex={0}
      className="container min-w-0 flex-1 truncate rounded-md border border-black p-1 text-left not-italic text-black caret-purple-dark focus:outline-4 focus:outline-offset-1 focus:outline-purple group-data-[open=true]:block group-data-[open=false]:hidden md:justify-start md:bg-white md:font-sans md:placeholder-shown:bg-white md:focus:outline md:group-data-[open=false]:invisible md:group-data-[open=false]:block md:group-data-[open=false]:w-0"
      type="text"
      value={query}
      placeholder="🔍Search Spell Cards..."
      onChange={(e) => handleSearchForm(e.target.value)}
    ></input>
  );
}
