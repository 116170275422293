import { useState } from "react";
import { FORM_ACTIONS } from "./formActions";
import { SpellCardInfo } from "./formReducer";

export enum FieldTypes {
  Text = "TEXT",
  Number = "NUMBER",
  Select = "SELECT",
  TextArea = "TEXTAREA",
  InputSelect = "INPUTSELECT",
  MultiSelect = "MULTISELECT",
  CheckBox = "CHECKBOX",
  Color = "COLOR",
  File = "FILE",
}

interface FieldProps {
  type: FieldTypes;
  fieldid: string;
  fieldState: SpellCardInfo;
  handler: Function;
  label?: string;
  size?: string;
  fieldtitle?: string;
  min?: number;
  max?: number;
  options?: string[];
  subtype?: FieldTypes;
}

// Tailwind Style variables
// *const inputStyleBase =
//   "h-11 rounded-md border border-white bg-black p-1 text-xl caret-blue-dark focus:outline focus:outline-2 focus:outline-offset-2 focus:outline-blue md:h-fit md:text-md-rem ";
// *const toggleMixin = "hidden peer-has-[:checked]:block";

export function Field(props: FieldProps) {
  //let fieldState = props.fieldid;
  //let fieldStates = props.fieldStates;
  //let newFieldStates = { ...fieldStates }
  const isOptional: boolean = props.fieldState[props.fieldid].optional;

  let inputs = <></>;

  switch (props.type) {
    case "NUMBER":
      inputs = <NumberInput {...props} />;
      break;
    case "TEXTAREA":
      inputs = <TextAreaInput {...props} />;
      break;
    case "SELECT":
      inputs = <SelectInput {...props} />;
      break;
    case "INPUTSELECT":
      inputs = <InputSelectInput {...props} />;
      break;
    case "MULTISELECT":
      inputs = <MultiSelectInput {...props} />;
      break;
    case "CHECKBOX":
      inputs = <CheckboxInput {...props} />;
      break;
    case "COLOR":
      inputs = <ColorInput {...props} />;
      break;
    case "FILE":
      inputs = <FileInput {...props} />;
      break;
    case "TEXT":
      inputs = <TextInput {...props} />;
      break;
    default:
      inputs = <TextInput {...props} />;
  }

  return (
    <div
      id={`${props.fieldid}-field`}
      className={
        props.type === "TEXTAREA" || props.type === "MULTISELECT" || isOptional
          ? "flex w-full flex-col items-start justify-start gap-4"
          : "flex w-full items-center gap-6 md:shrink-0 md:gap-4"
      }
    >
      <div
        id={`${props.fieldid}-field-label`}
        className={
          isOptional
            ? "group/label peer flex items-center justify-between self-stretch"
            : "group/label peer flex w-fit items-center justify-between"
        }
      >
        <FieldLabel {...props} />
        <FieldToggle {...props} />
      </div>
      {inputs}
    </div>
  );
}

/**
 * ### Controls whether field is included or not
 * ---
 * @param {*} props
 * @returns JSXElement and fieldState
 */
function FieldToggle(props: FieldProps) {
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckbox = () => {
    setIsChecked(!isChecked);
    props.handler({
      type: FORM_ACTIONS.SET_VIS,
      field: { name: props.fieldid },
    });
    console.log(props.fieldid + " has been clicked");
    console.log(props.fieldState[props.fieldid]["visibility"]);
  };

  // TODO: Add handler for pressing space on checkbox to toggle

  if (props.fieldState[props.fieldid]["optional"] === true) {
    return (
      <>
        <input
          id={`checkbox-${props.fieldid}`}
          className="peer/checkbox hidden focus:outline focus:outline-2 focus:outline-offset-1 focus:outline-blue"
          type="checkbox"
          name={props.fieldid}
          value={props.fieldState[props.fieldid]["visibility"]}
          checked={props.fieldState[props.fieldid]["visibility"]}
          onChange={handleCheckbox}
        />
        <label
          className="material-symbols-outlined color-white flex-1 cursor-pointer text-right text-5xl font-bold opacity-50 before:content-['add'] peer-checked/checkbox:opacity-100 peer-checked/checkbox:before:content-['remove'] md:text-2xl"
          htmlFor={`checkbox-${props.fieldid}`}
          tabIndex={0}
        ></label>
      </>
    );
  } else {
    return (
      <input
        id={`checkbox-${props.fieldid}`}
        className="peer/checkbox hidden focus:outline focus:outline-2 focus:outline-offset-1 focus:outline-blue"
        type="checkbox"
        name={props.fieldid}
        value={props.fieldState[props.fieldid]["visibility"]}
        checked={props.fieldState[props.fieldid]["visibility"]}
        readOnly
      />
    );
  }
}

/**
 * ### Label for <Field/> Element
 * ---
 * @param {FieldProps} props
 * @returns {JSX.Element}
 */
function FieldLabel(props: FieldProps): JSX.Element {
  return (
    <label
      className={
        "min-w-24 shrink text-xl font-bold italic opacity-50 group-has-[:checked]/label:opacity-100 md:inline-block md:min-w-36 md:py-0 md:text-md-rem"
      }
    >
      {props.fieldtitle}:
    </label>
  );
}

function TextInput(props: FieldProps) {
  return (
    <input
      name={props.fieldid}
      value={props.fieldState[props.fieldid]["value"]}
      tabIndex={0}
      className="hidden h-12 w-full min-w-0 self-stretch truncate rounded border border-white bg-black px-3 py-2.5 text-xl caret-blue focus:outline focus:outline-2 focus:outline-offset-2 focus:outline-blue peer-has-[:checked]:block md:h-fit md:px-2 md:py-1 md:text-md-rem"
      type="text"
      placeholder={props.fieldtitle}
      onChange={(e) =>
        props.handler({
          type: FORM_ACTIONS.SET_INPUT,
          field: { name: props.fieldid, value: e.target.value },
        })
      }
    ></input>
  );
}

function NumberInput(props: FieldProps) {
  return (
    <input
      name={props.fieldid}
      value={props.fieldState[props.fieldid]["value"]}
      tabIndex={0}
      className={`hidden h-12 w-full min-w-12 self-stretch truncate rounded border border-white bg-black px-3 py-2.5 text-xl caret-blue focus:outline focus:outline-2 focus:outline-offset-2 focus:outline-blue peer-has-[:checked]:block md:h-fit md:w-24 md:px-2 md:py-1 md:text-md-rem`}
      type="number"
      min={props.min}
      max={props.max}
      placeholder={props.fieldtitle}
      onChange={(e) =>
        props.handler({
          type: FORM_ACTIONS.SET_INPUT,
          field: { name: props.fieldid, value: e.target.value },
        })
      }
    ></input>
  );
}

/**
 * ### textarea input
 * ---
 * @param {*} props
 * @returns JSX Element
 */
function TextAreaInput(props: FieldProps) {
  return (
    <textarea
      name={props.fieldid}
      value={props.fieldState[props.fieldid]["value"]}
      className={
        "hidden h-36 w-full min-w-0 self-stretch truncate rounded border border-white bg-black px-3 py-2.5 text-xl caret-blue focus:outline focus:outline-2 focus:outline-offset-2 focus:outline-blue peer-has-[:checked]:block md:h-auto md:px-2 md:py-1 md:text-md-rem"
      }
      tabIndex={0}
      rows={window.innerWidth < 768 ? 1 : undefined}
      placeholder={props.fieldtitle}
      onChange={(e) =>
        props.handler({
          type: FORM_ACTIONS.SET_INPUT,
          field: { name: props.fieldid, value: e.target.value },
        })
      }
    ></textarea>
  );
}

function SelectInput(props: FieldProps) {
  return (
    <select
      name={props.fieldid}
      value={props.fieldState[props.fieldid]["value"]}
      className="hidden h-12 w-full min-w-12 self-stretch truncate rounded border border-white bg-black px-3 py-2.5 text-xl caret-blue focus:outline focus:outline-2 focus:outline-offset-2 focus:outline-blue peer-has-[:checked]:block md:h-fit md:w-1/3 md:p-2 md:text-md-rem"
      tabIndex={0}
      onChange={(e) =>
        props.handler({
          type: FORM_ACTIONS.SET_INPUT,
          field: { name: props.fieldid, value: e.target.value },
        })
      }
    >
      <option value="">{props.fieldtitle}</option>
      {props.options?.map((item, index) => {
        return (
          <option key={index} value={item}>
            {item}
          </option>
        );
      })}
    </select>
  );
}

function ColorInput(props: FieldProps) {
  return (
    <input
      name={props.fieldid}
      value={props.fieldState[`${props.fieldid}`]["value"]}
      tabIndex={0}
      className="h-16 w-16 bg-black focus:outline focus:outline-2 focus:outline-offset-1 focus:outline-blue"
      type={props.type}
      min={props.min}
      max={props.max}
      onChange={(e) =>
        props.handler({
          type: FORM_ACTIONS.SET_COLOR,
          field: { name: props.fieldid, value: e.target.value },
        })
      }
    ></input>
  );
}

function FileInput(props: FieldProps) {
  return (
    <input
      name={props.fieldid}
      type="file"
      accept="image/*"
      tabIndex={0}
      className="rounded-md border p-2"
      onChange={(e) => {
        let image = e.target.files ? e.target.files[0] : undefined;
        let reader = new FileReader();
        let imageData;
        reader.addEventListener(
          "load",
          () => {
            // convert image file to base64 string
            imageData = reader.result;
            //console.log(reader.result);

            // update form state
            props.handler({
              type: FORM_ACTIONS.SET_IMAGE,
              field: { name: props.fieldid, value: imageData },
            });
          },
          false,
        );

        if (image === undefined) {
          alert("Sorry, couldn't read the image");
        } else if (image.size > 1048576) {
          alert(
            "Sorry, this file is too big. Please upload an image under 1MB",
          );
        } else {
          reader.readAsDataURL(image);
        }
      }}
    ></input>
  );
}

function CheckboxInput(props: FieldProps) {
  return (
    <input
      name={`${props.fieldid}-field-checkbox`}
      id={`${props.fieldid}-checkbox`}
      value={props.fieldState[props.fieldid]["value"]}
      checked={props.fieldState[props.fieldid]["value"]}
      tabIndex={0}
      className={`h-10 w-10 justify-start rounded-md border border-black/5 p-1 font-sans text-black checked:accent-blue-dark focus:outline focus:outline-2 focus:outline-offset-1 focus:outline-blue md:block md:h-6 md:w-6`}
      type="checkbox"
      onChange={(e) => {
        props.handler({
          type: FORM_ACTIONS.SET_CHECKBOX,
          field: { name: props.fieldid },
        });
      }}
    ></input>
  );
}

/**
 * ### Input field for Spell Components
 * ---
 * @param {*} props
 * @returns JSXElement
 */
function MultiSelectInput(props: FieldProps) {
  // let shortNames = props.options?.map((name) => {
  //   return name.charAt(0).toUpperCase();
  // });
  return (
    <div className="flex flex-1 flex-col gap-4 self-stretch px-4 md:flex-row md:justify-between md:px-0">
      {props.options?.map((item, index) => {
        return (
          <div
            key={index}
            className="flex h-fit w-fit items-center gap-2 text-xl md:text-md-rem"
          >
            <input
              id={`multi-select-checkbox${index}`}
              name={`multi-select-checkbox${index}`}
              type="checkbox"
              value={props.fieldState[`${props.fieldid}`].value[index]}
              checked={props.fieldState[`${props.fieldid}`].value[index]}
              tabIndex={0}
              className="peer h-10 w-10 justify-start rounded-md border border-black/5 p-1 font-sans text-black checked:accent-blue-dark focus:outline focus:outline-2 focus:outline-offset-1 focus:outline-blue md:block md:h-6 md:w-6"
              onChange={() =>
                props.handler({
                  type: FORM_ACTIONS.SET_MULTISELECT,
                  field: { id: index },
                })
              }
            />
            <label
              className="min-w-0 font-bold italic text-white/70 outline-blue peer-checked:text-white md:w-fit md:border-none md:text-left"
              htmlFor={`multi-select-checkbox${index}`}
            >
              {item}
            </label>
          </div>
        );
      })}
    </div>
  );
}

function InputSelectInput(props: FieldProps) {
  return (
    <div className="hidden w-full min-w-0 gap-2 self-stretch peer-has-[:checked]:flex">
      <input
        name={props.fieldid}
        type={props.subtype}
        value={props.fieldState[props.fieldid]["value"]["input"]}
        tabIndex={0}
        className="h-12 min-w-0 flex-1 truncate rounded border border-white bg-black px-3 py-2.5 text-xl caret-blue focus:outline focus:outline-2 focus:outline-offset-2 focus:outline-blue md:h-fit md:px-2 md:py-1 md:text-md-rem"
        min={props.min}
        placeholder="--"
        onChange={(e) =>
          props.handler({
            type: FORM_ACTIONS.SET_INPUTSELECT,
            field: {
              name: props.fieldid,
              input: "input",
              value: e.target.value,
            },
          })
        }
      ></input>
      <select
        name={`${props.fieldid[1]}Select`}
        value={props.fieldState[props.fieldid]["value"]["unit"]}
        tabIndex={0}
        className="h-12 w-2/5 min-w-12 self-stretch truncate rounded border border-white bg-black px-3 py-2.5 text-xl caret-blue focus:outline focus:outline-2 focus:outline-offset-2 focus:outline-blue md:h-fit md:p-2 md:text-md-rem"
        onChange={(e) =>
          props.handler({
            type: FORM_ACTIONS.SET_INPUTSELECT,
            field: {
              name: props.fieldid,
              input: "select",
              value: e.target.value,
            },
          })
        }
      >
        <option value="">unit</option>
        {props.options?.map((option, index) => {
          return (
            <option key={index} value={option.toLowerCase()}>
              {option}
            </option>
          );
        })}
      </select>
    </div>
  );
}
