import React from "react";
import { useEffect } from "react";
import { useDraggable } from "@dnd-kit/core";
import { useDndMonitor } from "@dnd-kit/core";
import { useState } from "react";

export function Draggable(props: {
  id: string | number | undefined;
  children: React.ReactElement;
  zindex: number | undefined;
  isFocus: boolean;
  sort: string;
}) {
  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    id: `card-${props.id}`,
  });
  const [deltaX, setDeltaX] = useState(0);
  const [deltaY, setDeltaY] = useState(0);

  const style = transform
    ? {
        transform: `translate3d(${transform.x + deltaX}px, ${
          transform.y + deltaY
        }px, 0)`,
        zIndex: props.zindex,
      }
    : {
        transform: `translate3d(${deltaX}px, ${deltaY}px, 0)`,
        zIndex: props.zindex,
      };

  useEffect(() => {
    setDeltaX(0);
    setDeltaY(0);
  }, [props.sort]);

  useDndMonitor({
    onDragEnd() {
      //console.log(props.position.x);
      transform ? setDeltaX(deltaX + transform.x) : setDeltaX(deltaX);
      transform ? setDeltaY(deltaY + transform.y) : setDeltaY(deltaY);
      console.log(deltaX);
      console.log(deltaY);
    },
  });

  return (
    <button
      className={
        props.isFocus
          ? "draggable pointer-events-none snap-center rounded-md outline outline-purple md:pointer-events-auto print:!inline-block"
          : "draggable pointer-events-none snap-center rounded-md md:pointer-events-auto print:!inline-block"
      }
      data-print="true"
      ref={setNodeRef}
      style={style}
      {...listeners}
      {...attributes}
      data-id={props.id}
    >
      {props.children}
    </button>
  );
}
