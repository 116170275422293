import { Field } from "./Field.js";

import { AnimatePresence, motion } from "framer-motion";
import { useState } from "react";
import { FORM_ACTIONS } from "./formActions";
import { SpellCardInfo } from "./formReducer";
import { FieldTypes } from "./Field.js";
import { SpellCard } from "./SpellCard.js";
import {
  Button,
  ButtonBox,
  ButtonLabel,
  ButtonType,
  HTMLButtonType,
} from "./Button.js";

function Form(props: {
  createCard: React.FormEventHandler<HTMLFormElement>;
  fieldState: SpellCardInfo;
  dispatch: Function;
  isEditing: boolean;
}): React.ReactElement {
  let { createCard, fieldState, dispatch, isEditing } = props;
  // Color/Image Modal for mobile

  const switchTab = (tab: string) => {
    setTab(tab);
  };

  const screenWidth = window.innerWidth;
  const [tab, setTab] = useState(screenWidth < 768 ? "Appearance" : "Details");

  const tabs = ["Appearance", "Details"];

  return (
    <motion.form
      id="card-creator-form"
      className="container flex grow flex-col self-start overflow-hidden md:overflow-visible md:pt-10"
      name="card-creator-form"
      onSubmit={createCard}
    >
      <div
        id="creator-tabs"
        className="flex justify-around self-stretch pb-4 md:hidden"
      >
        {tabs.map((item) => (
          <li
            key={item}
            className="relative flex items-center justify-center gap-2 rounded-3xl px-6 py-2 text-center text-xl font-bold text-white"
            onClick={() => switchTab(item)}
          >
            <ButtonLabel label={item} weight="semibold" />

            {item === tab ? (
              <motion.div
                transition={{ duration: 0.1 }}
                className="absolute h-full w-full rounded-3xl border-2 border-blue"
                layoutId="underline"
              />
            ) : null}
          </li>
        ))}
      </div>
      <div
        id="tab-wrapper"
        className="flex shrink grow basis-0 items-center gap-10 self-stretch overflow-x-hidden pb-10 data-[tab='appearance']:justify-start data-[tab='details']:justify-end md:overflow-visible"
        data-tab={tab}
      >
        <AnimatePresence mode="wait" initial={false}>
          {tab === "Details" ? (
            <motion.div
              key="Details"
              initial={{ x: 300, opacity: 0 }}
              exit={{ x: 0, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              transition={{ duration: 0.1 }}
              id="form-fields"
              className="flex h-fit w-full flex-col divide-y divide-white/50 self-stretch"
            >
              <div id="spellname-field" className="self-stretch px-0">
                <Field
                  fieldid="spellname"
                  fieldtitle="Spell Name"
                  label="hidden"
                  type={FieldTypes.Text}
                  fieldState={fieldState}
                  handler={dispatch}
                />
              </div>
              {/*Header Inputs============================*/}
              <CreatorHeader
                fieldState={fieldState}
                fields={
                  <>
                    <Field
                      fieldid="spellschool"
                      fieldtitle="School"
                      type={FieldTypes.Select}
                      label="hidden"
                      options={[
                        "Abjuration",
                        "Conjuration",
                        "Divination",
                        "Enchantment",
                        "Evocation",
                        "Illusion",
                        "Necromancy",
                        "Transmutation",
                      ]}
                      fieldState={fieldState}
                      handler={dispatch}
                    />
                    <Field
                      fieldid="spelllevel"
                      fieldtitle="Level"
                      label="hidden"
                      type={FieldTypes.Number}
                      min={0}
                      max={9}
                      fieldState={fieldState}
                      handler={dispatch}
                    />
                    <Field
                      fieldid="spelltype"
                      fieldtitle="Type"
                      label="hidden"
                      type={FieldTypes.Select}
                      options={["Spell Save", "Spell Attack", "Summon"]}
                      fieldState={fieldState}
                      handler={dispatch}
                    />
                  </>
                }
              />
              {/*Description Inputs============================*/}
              <CreatorBody
                fields={
                  <>
                    <Field
                      fieldid="casttime"
                      fieldtitle="Casting Time"
                      type={FieldTypes.Text}
                      fieldState={fieldState}
                      handler={dispatch}
                    />
                    <Field
                      fieldid="range"
                      fieldtitle="Range"
                      type={FieldTypes.InputSelect}
                      subtype={FieldTypes.Text}
                      min={0}
                      options={["ft", "ft radius", "miles", "m", "km"]}
                      fieldState={fieldState}
                      handler={dispatch}
                    />
                    <Field
                      fieldid="duration"
                      fieldtitle="Duration"
                      type={FieldTypes.Text}
                      fieldState={fieldState}
                      handler={dispatch}
                    />
                    <Field
                      fieldid="spelltarget"
                      fieldtitle="Target"
                      type={FieldTypes.Text}
                      fieldState={fieldState}
                      handler={dispatch}
                    />
                    <Field
                      fieldid="damage"
                      fieldtitle="Damage"
                      type={FieldTypes.InputSelect}
                      subtype={FieldTypes.Number}
                      min={1}
                      options={["d4", "d6", "d8", "d10", "d12", "d20"]}
                      fieldState={fieldState}
                      handler={dispatch}
                    />
                    <Field
                      fieldid="spelldesc"
                      fieldtitle="Description"
                      type={FieldTypes.TextArea}
                      fieldState={fieldState}
                      handler={dispatch}
                    />
                    <Field
                      fieldid="upcastdesc"
                      fieldtitle="Upcast"
                      type={FieldTypes.TextArea}
                      fieldState={fieldState}
                      handler={dispatch}
                    />
                  </>
                }
              />
              {/*Footer Inputs==================================*/}
              <CreatorFooter
                fields={
                  <>
                    <Field
                      fieldid="spellcomp"
                      fieldtitle="Spell Components"
                      label="hidden"
                      type={FieldTypes.MultiSelect}
                      options={["Verbal", "Somatic", "Material"]}
                      fieldState={fieldState}
                      handler={dispatch}
                    />
                    <Field
                      fieldid="spellritual"
                      fieldtitle="Ritual"
                      type={FieldTypes.CheckBox}
                      fieldState={fieldState}
                      handler={dispatch}
                    />
                    <Field
                      fieldid="spellcon"
                      fieldtitle="Concentration"
                      type={FieldTypes.CheckBox}
                      fieldState={fieldState}
                      handler={dispatch}
                    />
                  </>
                }
              />
              {screenWidth > 768 ? (
                <AppearanceFields
                  fieldState={fieldState}
                  fields={
                    <>
                      <Field
                        fieldid="spellcolor"
                        fieldtitle="Color"
                        type={FieldTypes.Color}
                        fieldState={fieldState}
                        handler={dispatch}
                      />
                      <Field
                        fieldid="spellimg"
                        fieldtitle="Image"
                        type={FieldTypes.File}
                        fieldState={fieldState}
                        handler={dispatch}
                      />
                    </>
                  }
                />
              ) : null}
            </motion.div>
          ) : (
            <motion.div
              key="Appearance"
              initial={{ x: -300, opacity: 0 }}
              exit={{ x: 0, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              transition={{ duration: 0.2 }}
              className="container flex h-fit shrink-0 flex-col items-center divide-y divide-white/50 self-stretch"
            >
              <div className="flex flex-col items-center gap-4 self-stretch pb-4">
                <SpellCard id={-2} context={fieldState} isFocus={false} />
                <div className="self-start">
                  <Button
                    type={ButtonType.Underlined}
                    handler={switchTab}
                    args={"Details"}
                    label="Edit Details →"
                    icon="edit"
                    weight="semibold"
                  />
                </div>
              </div>

              <AppearanceFields
                fieldState={fieldState}
                fields={
                  <>
                    <Field
                      fieldid="spellcolor"
                      fieldtitle="Color"
                      type={FieldTypes.Color}
                      fieldState={fieldState}
                      handler={dispatch}
                    />
                    <Field
                      fieldid="spellimg"
                      fieldtitle="Image"
                      type={FieldTypes.File}
                      fieldState={fieldState}
                      handler={dispatch}
                    />
                  </>
                }
              />
            </motion.div>
          )}
        </AnimatePresence>
      </div>

      <div
        id="form-controls"
        className="container z-10 flex flex-row items-center justify-center gap-4 self-stretch bg-black py-5"
      >
        <Button
          icon="restart_alt"
          label=""
          size="3xl"
          type={ButtonType.None}
          handler={dispatch}
          args={{ type: FORM_ACTIONS.RESET }}
        />

        <ButtonBox
          label={isEditing ? "Update Card" : "Create Card"}
          type={ButtonType.Outlined}
          size="3xl"
          htmlType={HTMLButtonType.submit}
          handler={() => {}}
        />
      </div>
    </motion.form>
  );
}

function CreatorHeader(props: {
  fields: React.ReactElement;
  fieldState: SpellCardInfo;
}) {
  return (
    <div
      id="header-inputs"
      className="flex w-full min-w-0 flex-col gap-4 px-0 pb-4 pt-6 md:mt-2 md:justify-start md:gap-3"
    >
      {props.fields}
    </div>
  );
}

function CreatorBody(props: { fields: React.ReactElement }) {
  return (
    <div
      id="description-inputs"
      className="my-4 flex flex-col items-start justify-start gap-4 self-stretch px-0 py-6 leading-5 md:gap-3"
    >
      {props.fields}
    </div>
  );
}

function AppearanceFields(props: {
  fields: React.ReactElement;
  fieldState: SpellCardInfo;
}) {
  return (
    <div
      id="appearance-fields"
      className="mx-0 mb-4 flex min-w-0 flex-1 flex-col items-stretch gap-4 self-stretch overflow-hidden rounded-sm bg-cover px-0 py-4 md:flex md:w-full md:justify-start md:gap-3 md:self-stretch md:overflow-visible md:bg-transparent"
    >
      {props.fields}
    </div>
  );
}

function CreatorFooter(props: { fields: React.ReactElement }) {
  return (
    <div
      id="footer-fields"
      className="flex w-full min-w-0 flex-col items-start justify-start gap-4 border-t border-black/10 pb-3 pt-2 md:my-4 md:gap-3 md:pt-6"
    >
      {props.fields}
    </div>
  );
}

export { Form };
