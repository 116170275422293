import { SpellCardInfo } from "./formReducer";
//import { useState, useEffect, useRef } from "react";

export function SpellCard(props: {
  context: SpellCardInfo;
  id: number | undefined;
  isFocus?: boolean;
}) {
  const card = props.context;
  const id = props.id;

  // * Detect if castTime contains "action","bonus action", or "reaction"
  let actioncost;

  if (/reaction/i.test(card.casttime.value)) {
    actioncost = "reaction";
  } else if (/bonus/i.test(card.casttime.value)) {
    actioncost = "bonus";
  } else if (/action/i.test(card.casttime.value)) {
    actioncost = "action";
  } else {
    actioncost = null;
  }

  // * Set level to "cantrip" if level=0
  let spellLevel;

  if (card.spelllevel.value === "0") {
    spellLevel = "Cantrip";
    //console.log(spellLevel);
  } else if (card.spelllevel.value === undefined) {
  } else {
    spellLevel = `Level ${card.spelllevel.value}`;
  }

  // * Set icon for spell types
  let typeIcon;

  if (card.spelltype.value === "Spell Attack") {
    typeIcon = "spellattack";
  } else if (card.spelltype.value === "Spell Save") {
    typeIcon = "spellsave";
  } else if (card.spelltype.value === "Summon") {
    typeIcon = "summon";
  } else {
    typeIcon = null;
  }

  return (
    <div
      id={`${id}`}
      className={
        "spellcard flex h-[507px] w-72 shrink-0 flex-col items-start gap-1 overflow-hidden rounded-md bg-white text-left font-serif font-medium italic text-black shadow-lg active:outline active:outline-purple print:m-4 print:shadow-none print:outline-dashed print:outline-1 print:outline-offset-2"
      }
      data-print="true"
    >
      <div
        id={`${card.spellname.value}-header`}
        className={`flex h-9 w-full items-center gap-3 p-3`}
        style={{ backgroundColor: `${card.spellcolor.value}` }}
      >
        <p className="absolute -top-6">Card#:{card.id}</p>
        <p className="flex-1">{card.spellschool.value}</p>
        <p>{spellLevel}</p>
        {typeIcon !== null ? (
          <img
            className=""
            src={process.env.PUBLIC_URL + `./images/icon-${typeIcon}.svg`}
            alt={`${typeIcon} icon`}
          />
        ) : null}
      </div>
      <div
        id={`${card.spellname.value}-img`}
        className="mx-6 mt-2 flex flex-1 items-stretch self-stretch overflow-hidden rounded-sm"
        style={{ backgroundColor: `${card.spellcolor.value}` }}
      >
        {
          <img
            className="h-full w-full self-center rounded-md object-cover"
            src={
              typeof card.spellimg.value === "string" ? card.spellimg.value : ""
            }
            alt={card.spellname.value}
          />
        }
      </div>
      <div
        id={`${card.spellname.value}-title`}
        className="mx-6 inline h-fit self-stretch pb-3 pt-2 font-display text-4xl font-bold not-italic"
      >
        {card.spellname.value}&nbsp;
        <span className="inline-block">
          {actioncost !== null ? (
            <img
              className="inline"
              src={process.env.PUBLIC_URL + `./images/icon-${actioncost}.svg`}
              alt={`${actioncost} icon`}
            />
          ) : null}
        </span>
      </div>
      <div
        id={`${card.spellname.value}-desc`}
        className="mx-6 flex shrink-0 flex-col items-start gap-2 self-stretch leading-5"
      >
        <ul className="list-none">
          <CardField
            name="casttime"
            label="Casting Time:"
            value={card.casttime.value}
            optional={card.casttime.optional}
          />
          <CardField
            name="range"
            label="Range:"
            value={card.range.value.input + " " + card.range.value.unit}
            optional={card.range.optional}
          />
          <CardField
            name="duration"
            label="Duration:"
            value={card.duration.value}
            optional={card.duration.optional}
          />
          <CardField
            name="target"
            label="Target:"
            value={card.spelltarget.value}
            visibility={card.spelltarget.visibility}
            optional={card.spelltarget.optional}
          />
          <CardField
            name="damage"
            label="Damage:"
            value={`${card.damage.value.input}${card.damage.value.unit} ${typeIcon === "spellattack" ? " + Ability Mod + Proficiency" : ""}`}
            optional={card.damage.optional}
            visibility={card.damage.visibility}
          />
        </ul>
        <p
          id="desc-field"
          className={
            card.spelldesc.visibility
              ? "flex flex-col items-start leading-3"
              : "hidden"
          }
        >
          {card.spelldesc.value}
        </p>
        <div
          id="upcast-field"
          className={
            card.upcastdesc.visibility
              ? "flex flex-col items-start leading-3"
              : "hidden"
          }
        >
          <p className="pt-4 font-bold">At Higher Levels:</p>
          <p className="whitespace-pre-wrap py-2 text-sm">
            {card.upcastdesc.value}
          </p>
        </div>
      </div>
      <div
        id={`${card.spellname.value}-footer`}
        className="mx-6 flex items-start gap-3 self-stretch border-t border-black/10 pb-3 pt-2 text-black/25"
      >
        <p className="flex-1">
          {card.spellcomp.value[0] ? "V" : ""}{" "}
          {card.spellcomp.value[1] ? "S" : ""}{" "}
          {card.spellcomp.value[2] ? "M" : ""}
        </p>
        <p id="ritual-marker" className="">
          {card.spellritual.value ? "Ritual" : ""}
        </p>
        <p id="concentration-marker" className="">
          {card.spellcon.value ? "Concentration" : ""}
        </p>
      </div>
    </div>
  );
}

function CardField(props: {
  name: string;
  visibility?: boolean;
  optional: boolean;
  value: string;
  label: string;
}) {
  return (
    <li
      id={`${props.name}-field`}
      className={
        props.optional ? (props.visibility ? "flex" : "hidden") : "flex"
      }
    >
      <strong>{props.label}&nbsp;</strong>
      {props.value}
    </li>
  );
}
