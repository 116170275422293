export enum FORM_ACTIONS {
  RESET = "RESET",
  SET_FIELDS = "SET_FIELDS",
  SET_INPUT = "SET_INPUT",
  SET_INPUTSELECT = "SET_INPUTSELECT",
  SET_CHECKBOX = "SET_CHECKBOX",
  SET_MULTISELECT = "SET_MULTISELECT",
  SET_VIS = "SET_VIS",
  SET_IMAGE = "SET_IMAGE",
  SET_COLOR = "SET_COLOR",
}
