let version = 2;

export const initDB = () => {
  return new Promise((resolve) => {
    let request = indexedDB.open("imageStorage");

    request.onupgradeneeded = () => {
      console.log(
        "This version of the database is older than the current version.",
      );

      var db: IDBDatabase = request.result;

      if (!db.objectStoreNames.contains("id")) {
        db.createObjectStore("imageStorage", {
          keyPath: "id",
        });
      }
    };
    request.onsuccess = () => {
      var db = request.result;
      version = db.version;

      const tx = db.transaction("imageStorage", "readwrite");
      const store: IDBObjectStore = tx.objectStore("imageStorage");
      store.put({ id: "hasUpdate", image: true });

      resolve(true);
    };
    request.onerror = () => {
      console.log("Error with creating IndexedDB");
      resolve(false);
    };
  });
};

export function addImage(index: number, item: string) {
  return new Promise((resolve) => {
    let request = indexedDB.open("imageStorage", version);

    request.onsuccess = () => {
      var db = request.result;
      const tx = db.transaction("imageStorage", "readwrite");
      const store: IDBObjectStore = tx.objectStore("imageStorage");

      store.put({ id: index, image: item });
      setHasUpdate(true);
      resolve([index, item]);

      //console.log("Adding image to imageStorage...", index, item);
    };

    request.onerror = () => {
      const error = request.error?.message;
      if (error) {
        resolve(error);
      } else {
        resolve("Unknown error");
      }
    };
  });
}

export function checkHasUpdate() {
  return new Promise<boolean>((resolve) => {
    let request = indexedDB.open("imageStorage");
    console.log("Executing checkHasUpdate...");

    request.onsuccess = () => {
      var db = request.result;
      const tx = db.transaction("imageStorage");
      const store = tx.objectStore("imageStorage");

      const res = store.get("hasUpdate");

      res.onsuccess = () => {
        if (res.result.image) {
          console.warn(res.result.image);
          resolve(res.result.image);
        }
      };
    };
  });
}

export function setHasUpdate(value: boolean) {
  return new Promise<boolean>((resolve) => {
    let request = indexedDB.open("imageStorage");
    console.log("Executing setHasUpdate...");

    request.onsuccess = () => {
      var db = request.result;
      const tx = db.transaction("imageStorage", "readwrite");
      const store = tx.objectStore("imageStorage");

      console.log("Changing hasUpdate to " + value);
      store.put({ id: "hasUpdate", image: value });
      resolve(value);

      //console.log(key);
    };
  });
}

export function deleteImage(key: number) {
  return new Promise((resolve) => {
    let request = indexedDB.open("imageStorage", version);

    request.onsuccess = () => {
      console.log("deleteImage - open request successfull");
      var db = request.result;
      const tx = db.transaction("imageStorage", "readwrite");
      const store = tx.objectStore("imageStorage");
      const res = store.delete(key);

      res.onsuccess = () => {
        console.log("Image has been deleted");
        setHasUpdate(true);
        resolve(true);
      };

      res.onerror = () => {
        console.error("Error with deleting card");
        resolve(false);
      };
    };
  });
}

export function deleteAll() {
  return new Promise((resolve) => {
    let request = indexedDB.open("imageStorage", version);

    request.onsuccess = (e) => {
      var db = request.result;
      const tx = db.transaction("imageStorage", "readwrite");
      const store = tx.objectStore("imageStorage");
      const res = store.clear();
      store.put({ id: "hasUpdate", image: true });

      res.onsuccess = () => {
        console.log("Database has been cleared");
        resolve(true);
      };

      res.onerror = (error) => {
        console.log("Error with clearing the database: " + error);
        resolve(false);
      };
    };
  });
}

export async function copyImage(prevKey: number, newKey: number) {
  console.log("copyImage(): Copying image to imageStorage...");
  try {
    console.log("copyImage(): getting image");
    const image = await getImage(prevKey);
    console.log("copyImage(): adding image");
    const result = await addImage(newKey, image);

    console.log(result);
  } catch (error) {
    console.error(error);
  }
}

export function getImage(key: number) {
  return new Promise<string>((resolve) => {
    let request = indexedDB.open("imageStorage");

    request.onsuccess = (e) => {
      var db = request.result;
      console.log("Getting image from imageStorage...");
      //console.log(key);
      const tx = db.transaction("imageStorage");
      const store = tx.objectStore("imageStorage");
      const res = store.get(key);

      res.onsuccess = () => {
        if (res.result?.image) {
          //console.log(res.result.image);
          resolve(res.result.image);
        }
      };
    };
  });
}
