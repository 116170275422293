export enum ButtonType {
  Outlined = "OUTLINED",
  Underlined = "UNDERLINED",
  None = "NONE",
}

export enum HTMLButtonType {
  button = "button",
  submit = "submit",
  reset = "reset",
}

type ButtonLabelProps = {
  size?: string;
  weight?: string;
  label?: string;
} & typeof defaultLabelProps;

interface ButtonProps extends ButtonLabelProps {
  type: ButtonType;
  icon?: string;
  handler?: Function;
  args?: any;
  htmlType?: HTMLButtonType;
}

const defaultLabelProps = {
  size: "2xl",
  weight: "black",
};

const defaultButtonProps = {
  ...defaultLabelProps,
  htmlType: HTMLButtonType.button,
};

export const ButtonLabel = (props: ButtonLabelProps): JSX.Element => {
  const style = `text-${props.size} font-${props.weight}`;
  return <span className={style}>{props.label}</span>;
};

ButtonLabel.defaultProps = defaultLabelProps;

export const Button = (props: ButtonProps): JSX.Element => {
  const iconStyle = `material-symbols-outlined text-${props.size} font-${props.weight}`;
  return (
    <button
      type={props.htmlType !== undefined ? props.htmlType : "button"}
      className={
        props.type === "OUTLINED"
          ? "focus-visible::bg-blue-dark inline-flex w-fit items-center justify-start gap-2 rounded-3xl border-4 border-white px-5 py-2 active:bg-blue-dark"
          : props.type === "UNDERLINED"
            ? "inline-flex w-fit items-center justify-center gap-2 border-b-2 border-white hover:border-blue focus:border-blue focus:bg-blue/50 active:border-blue active:bg-blue/50"
            : "inline-flex w-fit items-center justify-center gap-2 focus-visible:text-blue active:text-blue md:hover:text-blue"
      }
      onClick={
        props.args === undefined
          ? () => props.handler
          : props.handler !== undefined
            ? () => props.handler?.(props.args)
            : undefined
      }
    >
      {props.icon ? <span className={iconStyle}>{props.icon}</span> : null}
      {props.label ? <ButtonLabel {...props} /> : null}
    </button>
  );
};

Button.defaultProps = defaultButtonProps;
export const ButtonBox = (props: ButtonProps): JSX.Element => {
  return (
    <div
      role="button"
      className="flex w-fit flex-col items-start justify-start gap-2 rounded px-4 py-3 hover:border-2 hover:border-blue hover:bg-blue/50 focus-visible:border-2 focus-visible:border-blue focus-visible:bg-blue/50 focus-visible:outline-blue"
      onClick={
        props.args === undefined
          ? () => props.handler
          : props.handler !== undefined
            ? () => props.handler?.(props.args)
            : undefined
      }
    >
      <Button {...props} />
    </div>
  );
};
ButtonBox.defaultProps = defaultButtonProps;
